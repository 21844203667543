// ListSort

<template>
    <v-container fluid ma-0 pa-0>
        <v-row no-gutters>
        <v-col cols="12" sm="3" class="pt-0">
        <v-card tile class="grey darken-3 overflow-hidden">
            <v-app-bar absolute dark dense color="blue-grey darken-2"
                scroll-target="#playground" elevate-on-scroll
            >
                <v-toolbar-title>Fields</v-toolbar-title>
            </v-app-bar>
            <v-sheet id="playground" class="grey darken-3 overflow-y-auto" max-height="700">
                <v-container fluid style="height: 1500px;padding-top: 50px;"
                    @drop.stop="drop(1, $event)" @dragover.prevent
                >
					<v-row v-for="(item, index) in aItems" :key="index" class="ma-0 pa-0">
						<v-col cols="12" class="ma-0 pb-0 pt-2 px-2">
							<v-card class="elevation-1 grey darken-2 ma-0 px-1"
								@mousedown="dragEnable" style="cursor: move;"
								:draggable="dragEnabled"
								@dragover.prevent
								@dragstart="dragstart(item, index, 1, $event)"
								@dragend="dragend(item, index, $event)"
							>{{ item.column_name + ' [' + item.pos + ']' }}</v-card>
						</v-col>
					</v-row>
                </v-container>
            </v-sheet>
        </v-card>
        </v-col>
        <v-divider />
        <v-col cols="12" sm="9" class="pl-0 pt-0">
        <v-card tile class="grey darken-3">
            <v-app-bar absolute dark dense flat color="blue-grey darken-2">
                <v-toolbar-title>Tabelle {{ title }}</v-toolbar-title>
				<v-spacer/>
				<v-btn small class="ml-3" color="success" :disabled="!isDirty" @click="saveData">Save</v-btn>
            </v-app-bar>
            <v-sheet class="grey darken-3 ma-0 pa-1" height="700">
                <v-container fluid style="height: 500px;padding-top: 62px;"
				    @drop.stop="drop(2, $event)" @dragover.prevent
                >
					<v-row v-for="(item, index) in bItems" :key="index" justify="start">
						<v-col class="ma-0 pa-1" :cols="item.width">
							<v-card class="elevation-1 grey darken-2 ma-0 pa-3"
								@mousedown="dragEnable" style="cursor: move;"
								:draggable="dragEnabled"
								@dragover.prevent
								@dragstart="dragstart(item, index, 2, $event)"
								@dragend="dragend(item, index, $event)"
								@dragenter.prevent="dragenter(item, index, $event)"
							>{{ item.column_name + ' [' + item.pos + ']' }}</v-card>
						</v-col>
					</v-row>
                </v-container>
            </v-sheet>
        </v-card>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
    name: 'ListSort',
    components: {},
    props: {
        ctx: {
            type: Object, default: () => ({})
        },
    },
    data: () => ({
        msgError: '',
        isDirty: false,
        loading: false,
        items: [],
        dragEnabled: false,
        dragItem: {},
        dragItemIdx: null,
        dragEnterIdx: null,
        dragCtx: null,
        hoverItem: {},
    }),
    computed: {
        ...mapGetters({
            cfglists: 'configs/getCfgLists',
        }),
        aItems: function () {
            var self = this
            if(!self.items.length) {return []}
            return self.items.filter(e => {
                return (!e.pos)
            })
        },
        bItems: function () {
            var self = this
            if(!self.items.length) {return []}
            return self.items.filter(e => {
                return (!!e.pos)
            })
        },
		title: function() {
			return JSON.stringify(this.ctx)
		},
    },
    methods: {
        fetchData() {
            this.loading = true
            let param = this.ctx
            let self = this
            //console.log('fetch lists [' + JSON.stringify(param) + ']')
            this.$store.dispatch('configs/getCfgLists', param)
            .then((response) => {
                //console.log('fetched lists [' + JSON.stringify(param) + ']')
                //console.log(this.cfglists)
                this.items = JSON.parse(JSON.stringify(this.cfglists))
                this.items.map(x=>x.width=x.width || 4)
                this.sortItems(1)
                //this.items[0].pos = 99
                self.loading = false
            })
            .catch((error) => {
                //console.log('fetched lists error [' + JSON.stringify(param) + ']')
                console.log(error)
                self.loading = false
            });
        },
		sortItems(ctx) {
            //console.log('sort lists begin [' + JSON.stringify(this.items) + ']')
            if(ctx == 1) {
                this.items.sort((a, b) => {
                    return (a.pos || b.pos) ? a.pos - b.pos : (a.column_name < b.column_name) ? -1 : 1
                })
            } else {
                this.items.sort((a, b) => {
                    return a.pos - b.pos
                })
            }
            this.items.forEach((e, index) => {
                if(e.pos) {e.pos = index * 10 + 10;}
            })
            //console.log('sort lists result [' + JSON.stringify(this.items) + ']')
        },
        dragEnable() {
            this.dragEnabled = true
        },
        dragstart(item, idx, ctx) {
            if(this.dragEnabled && item) {
                this.dragItem = item
                this.dragItemIdx = idx
                this.dragCtx = ctx
                //console.log('dragstart:', item.column_name, 'idx:', idx, 'ctx:', ctx)
            }
        },
        dragend(item, idx) {
            if(this.dragEnabled && item) {
                this.dragEnabled = false
                this.dragItem = {}
                this.dragCtx = 0
                //console.log('dragend', item.name, 'idx:', idx)
                this.isDirty = true
                this.ctx.dirty = true
            }
        },
        dragenter(item, idx) {
            if(this.dragCtx == 2) {
                if(this.dragEnabled && item && item.id != this.dragItem.id) {
                    //console.log('dragenter:', item.column_name, 'idx:', idx)
                    const drapItemPos = this.dragItem.pos
                    //if(this.dragEnterIdx + 1 == idx || this.dragItemIdx + 1 == idx) {this.dragItem.pos = item.pos + 5}
                    //else {this.dragItem.pos = item.pos - 5}
                    this.dragItem.pos = item.pos
                    item.pos = drapItemPos
                    this.sortItems(this.dragCtx)
                    //this.dropIdx = idx
                    //this.dragEnterIdx = idx
                }
            }
        },
        drop(ctx) {
            if(this.dragCtx != ctx) {
                if(ctx == 1) {this.dragItem.pos = 0} else {this.dragItem.pos = 999}
                this.dragCtx = 0
                this.sortItems(ctx)
            }
        },
        saveData() {
            let param = {
                ctx: this.ctx,
                cfglists: this.items,
            }
            this.$emit('dying', param)
            this.isDirty = false
        },
    },
    mounted() {
        //console.log('mounted: ' + JSON.stringify(this.ctx))
		this.fetchData()
    },
    beforeDestroy() {
        //console.log('destroying: ' + JSON.stringify(this.ctx))
        let param = {
            ctx: this.ctx,
            cfglists: this.items,
        }
        this.$emit('dying', param)
    },
    watch: {},
}
</script>

<style scoped>
</style>